<template>
  <div class="file-combiner">
    <div class="button-group">
      <button @click="combineFiles" :disabled="isLoading" class="combine-btn">
        <v-icon size="20" color="white">download</v-icon>

        {{ isLoading ? "Processing..." : "Download All  " }}
      </button>
      <!-- <button
        v-if="combinedFiles.length > 0"
        @click="downloadIndividualFiles"
        class="download-individual-btn"
      >
        Download Individual Files
      </button> -->
    </div>

    <p v-if="error" class="error">{{ error }}</p>
    <!-- <div v-if="isLoading" class="progress">
      <div class="progress-text">Processing files...</div>
      <div class="progress-bar">
        <div
          class="progress-bar-fill"
          :style="{ width: `${processingProgress}%` }"
        ></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { PDFDocument } from "pdf-lib";
import axios from "axios";
import mammoth from "mammoth";

export default {
  name: "FilesCombiner",

  data() {
    return {
      isLoading: false,
      error: null,
      combinedFiles: [],
      processingProgress: 0,
      supportedFormats: ["pdf", "png", "jpg", "jpeg", "docx"],
    };
  },

  props: {
    s3Links: {
      type: Array,
      required: true,
      validator: (value) =>
        value.length > 0 && value.every((link) => typeof link === "string"),
    },
    outputFileName: {
      type: String,
      default: "combined-files.pdf",
    },
  },

  methods: {
    async processFile(link) {
      const extension = this.validateFileType(link);
      const fileData = await this.downloadFile(link);
      let pdfBytes;

      switch (extension) {
        case "pdf":
          const pdf = await PDFDocument.load(fileData);
          pdfBytes = await pdf.save();
          break;

        case "docx":
          pdfBytes = await this.convertDocxToPdf(fileData);
          break;

        case "jpg":
        case "jpeg":
        case "png":
          pdfBytes = await this.convertImageToPdf(fileData, extension);
          break;
      }

      return new Blob([pdfBytes], { type: "application/pdf" });
    },

    validateFileType(link) {
      const extension = link.split(".").pop().toLowerCase();
      if (!this.supportedFormats.includes(extension)) {
        throw new Error(
          `Unsupported file type: ${extension}. Supported formats: ${this.supportedFormats.join(
            ", "
          )}`
        );
      }
      return extension;
    },

    async downloadFile(url) {
      try {
        const response = await axios.get(url, {
          responseType: "arraybuffer",
          timeout: 30000,
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.updateProgress(percentCompleted);
            }
          },
        });
        return response.data;
      } catch (error) {
        throw new Error(
          `Failed to download file from ${url}: ${error.message}`
        );
      }
    },

    async convertDocxToPdf(docxBytes) {
      try {
        const result = await mammoth.convertToHtml({ arrayBuffer: docxBytes });
        const html = result.value;
        const pdfDoc = await PDFDocument.create();
        let currentPage = pdfDoc.addPage();
        const { width, height } = currentPage.getSize();
        const fontSize = 12;
        const margin = 50;
        const words = html.replace(/<[^>]*>/g, " ").split(/\s+/);
        let y = height - margin;
        let line = "";

        for (const word of words) {
          if (y < margin) {
            currentPage = pdfDoc.addPage();
            y = height - margin;
          }

          if (
            line.length + word.length <
            (width - 2 * margin) / (fontSize / 2)
          ) {
            line += word + " ";
          } else {
            currentPage.drawText(line, {
              x: margin,
              y,
              size: fontSize,
            });
            y -= fontSize * 1.5;
            line = word + " ";
          }
        }

        if (line) {
          currentPage.drawText(line, {
            x: margin,
            y,
            size: fontSize,
          });
        }

        return await pdfDoc.save();
      } catch (error) {
        throw new Error(`DOCX conversion failed: ${error.message}`);
      }
    },

    async convertImageToPdf(imageBytes, fileExtension) {
      try {
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage();

        let image;
        if (["jpg", "jpeg"].includes(fileExtension)) {
          image = await pdfDoc.embedJpg(imageBytes);
        } else if (fileExtension === "png") {
          image = await pdfDoc.embedPng(imageBytes);
        } else {
          throw new Error(`Unsupported image format: ${fileExtension}`);
        }

        const { width: imgWidth, height: imgHeight } = image.scale(1);
        const { width: pageWidth, height: pageHeight } = page.getSize();

        const scale =
          Math.min(pageWidth / imgWidth, pageHeight / imgHeight) * 0.9;
        const finalWidth = imgWidth * scale;
        const finalHeight = imgHeight * scale;

        const x = (pageWidth - finalWidth) / 2;
        const y = (pageHeight - finalHeight) / 2;

        page.drawImage(image, {
          x,
          y,
          width: finalWidth,
          height: finalHeight,
        });

        return await pdfDoc.save();
      } catch (error) {
        throw new Error(`Image conversion failed: ${error.message}`);
      }
    },

    updateProgress(value) {
      this.processingProgress = Math.min(value, 100);
    },

    async combineFiles() {
      if (this.isLoading) return;

      try {
        this.isLoading = true;
        this.error = null;
        this.combinedFiles = [];
        this.processingProgress = 0;

        // Process each file individually
        const totalFiles = this.s3Links.length;
        for (let i = 0; i < totalFiles; i++) {
          const link = this.s3Links[i];
          const pdfBlob = await this.processFile(link);
          this.combinedFiles.push(pdfBlob);
          this.updateProgress((i + 1) * (100 / totalFiles));
        }

        // Create combined version
        const mergedPdf = await PDFDocument.create();

        for (const blob of this.combinedFiles) {
          const arrayBuffer = await blob.arrayBuffer();
          const pdf = await PDFDocument.load(arrayBuffer);
          const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
          pages.forEach((page) => mergedPdf.addPage(page));
        }

        const mergedPdfBytes = await mergedPdf.save();
        const mergedBlob = new Blob([mergedPdfBytes], {
          type: "application/pdf",
        });

        // Download the combined PDF
        this.downloadBlob(mergedBlob, this.outputFileName);

        // Emit success event
        this.$emit("combination-complete", {
          combinedBlob: mergedBlob,
          individualBlobs: this.combinedFiles,
        });
      } catch (err) {
        this.error = `Error: ${err.message}`;
        console.error("File combination failed:", err);
        this.$emit("combination-error", err);
      } finally {
        this.isLoading = false;
        this.processingProgress = 0;
      }
    },

    downloadBlob(blob, fileName) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      setTimeout(() => URL.revokeObjectURL(link.href), 1000);
    },

    downloadIndividualFiles() {
      this.combinedFiles.forEach((blob, index) => {
        const fileName = `file-${index + 1}.pdf`;
        this.downloadBlob(blob, fileName);
      });
    },

    getFileNameFromUrl(url) {
      try {
        const urlParts = url.split("/");
        return urlParts[urlParts.length - 1];
      } catch {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.file-combiner {
  padding: 1rem;
  max-width: 600px;
  margin: 0 auto;
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.combine-btn,
.download-individual-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.combine-btn {
  background-color: #4caf50;
  color: white;
}

.combine-btn:hover {
  background-color: #45a049;
}

.download-individual-btn {
  background-color: #2196f3;
  color: white;
}

.download-individual-btn:hover {
  background-color: #1e88e5;
}

.combine-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  color: #ff0000;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #ffebee;
  border-radius: 4px;
}

.progress {
  margin-top: 1rem;
}

.progress-text {
  color: #666;
  margin-bottom: 0.5rem;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}
</style>
